import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import SiteMetadata from "../componets/SiteMetadata/SiteMetadata"
import Header from "../componets/Header/Header"
import Footer from "../componets/Footer/Footer"
import Cgv from "../componets/Cgv/Cgv"
import "./globals.css"
import { graphql, useStaticQuery } from "gatsby"

export const query = graphql`
  query {
    strapiCgv {
      prelude
      definition_title
      definition_content
      modality_title
      modality_content
      publicity_title
      publicity_content
      price_title
      price_content
      regulation_title
      regulation_content
      responsability_title
      responsability_content
      claim_title
      claim_content
    }
    logo: file(
      absolutePath: {
        regex: "/images/logo_seformer-f59ecb37be2dc067b743d7ee0a113952.webp/"
      }
    ) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Home(props) {
  const { data } = props
  const infos = data.strapiCgv

  return (
    <div>
      <SiteMetadata />
      <Header {...props} logo={data.logo} />
      <Cgv infos={infos} />
      <Footer />
    </div>
  )
}
