import React from 'react';
import images from '../../assets/images';
import {Link} from "gatsby";
import './Cgv.css'
import {stripTags} from "../../services/helpers";

var md = require('markdown-it')({
    html: true,
    xhtmlOut: true,
    breaks: true,
});

function Cgv(props) {
    const {infos} = props;
    return (
        <>
            <section className="NavsingleFormation">
                <div className='container container-seformer'>
                    <ul className="breadcrumb-single-formation">
                        <li><Link to="/">Accueil</Link></li>
                        <li><img src={images.breadcrumbsSeparator} alt='breadcrumbs separator'/></li>
                        <li className="active">Conditions Générales de ventes</li>
                    </ul>
                </div>
            </section>
            <div className="pagecgv pt-2 pb-5">
                <div className="titlepage-interne">
                    <div className='container container-seformer'>
                        <div className="d-flex justify-content-center">
                            <h2 className="text-center title-section mx-auto my-3">Conditions Générales de ventes</h2>
                        </div>
                    </div>
                </div>
                <div className='container container-seformer'>
                    <div className="ml-120">
                        <div className="my-5 section-cgv">
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.prelude), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h3 className="h3cgv">{infos.definition_title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.definition_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h4 className="h4cgv">{infos.modality_title}</h4>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.modality_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h4 className="h4cgv">{infos.publicity_title}</h4>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.publicity_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h4 className="h4cgv">{infos.price_title}</h4>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.price_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h4 className="h4cgv">{infos.regulation_title}</h4>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.regulation_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h4 className="h4cgv">{infos.responsability_title}</h4>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.responsability_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                        <div className="my-5 section-cgv">
                            <h4 className="h4cgv">{infos.claim_title}</h4>
                            <div dangerouslySetInnerHTML={{ __html: stripTags(md.render(infos.claim_content), '<p><br><ol><ul><li><strong><b><i><u>') }}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cgv;